<template>
  <section
    v-if="Object.keys(pricing).length"
    id="pricing-plan"
  >
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-5">
        Pricing Plans
      </h1>
      <p class="mb-2 pb-75">
        All plans include personal support and all build failures will be recovered. Choose the best plan to fit your needs.
      </p>
      <div class="d-flex align-items-center justify-content-center mb-5 pb-50">
        <h6 class="mr-1 mb-0">
          Per Build
        </h6>
        <b-form-checkbox
          id="priceSwitch"
          v-model="status"
          name="price-switch"
          value="monthly"
          unchecked-value="build"
          switch
          @input="tooglePlan"
        />
        <h6 class="ml-50 mb-0">
          Monthly
        </h6>
      </div>
    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row>
          <b-col md="4">
            <b-card
              align="center"
            >
              <!-- img -->
              <b-img
                v-if="pricing.basicPlan.img"
                :src="pricing.basicPlan.img"
                class="mb-2 mt-5"
                alt="pricing basic package image"
              />
              <!--/ img -->
              <h3>{{ pricing.basicPlan.title }}</h3>
              <b-card-text>
                {{ pricing.basicPlan.subtitle }}
              </b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ !monthlyPlanShow ? pricing.basicPlan.buildPrice:pricing.basicPlan.monthlyPlan.perMonth }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/{{ monthlyPlanShow ? 'month' : '3 builds' }}</sub>
                </div>
                <small
                  v-show="monthlyPlanShow"
                  class="annual-pricing text-muted"
                >Unlimited builds</small>
              </div>
              <!--/ annual plan -->

              <!-- subscription plan benefit -->
              <b-list-group
                v-if="monthlyPlanShow"
                class="list-group-circle text-left"
              >
                <b-list-group-item
                  v-for="(data,index) in pricing.basicPlan.subscriptionPlanBenefits"
                  :key="index"
                >
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ subscription plan benefit -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data,index) in pricing.basicPlan.planBenefits"
                  :key="index"
                >
                  <del v-if="!data.available">{{ data.message }}</del>
                  <div v-else>
                    {{ data.message }}
                  </div>
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                block
                class="mt-2"
                variant="outline-success"
                @click="buyProduct('basic')"
              >
                <div v-if="!monthlyPlanShow">
                  Buy Builds
                </div>
                <div v-if="monthlyPlanShow">
                  Subscribe
                </div>
              </b-button>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card
              class="popular"
              align="center"
            >
              <div
                v-show="pricing.standardPlan.popular"
                class="pricing-badge text-right"
              >
                <b-badge
                  variant="light-primary"
                  pill
                >
                  Popular
                </b-badge>
              </div>
              <!-- img -->
              <b-img
                v-if="pricing.standardPlan.img"
                :src="pricing.standardPlan.img"
                class="mb-1"
                alt="pricing standard package image"
              />
              <!--/ img -->
              <h3>{{ pricing.standardPlan.title }}</h3>
              <b-card-text>{{ pricing.standardPlan.subtitle }}</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ !monthlyPlanShow ? pricing.standardPlan.buildPrice:pricing.standardPlan.monthlyPlan.perMonth }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/{{ monthlyPlanShow ? 'month' : '3 builds' }}</sub>
                </div>
                <small
                  v-show="monthlyPlanShow"
                  class="annual-pricing text-muted"
                >Unlimited builds</small>
              </div>
              <!--/ annual plan -->

              <!-- subscription plan benefit -->
              <b-list-group
                v-if="monthlyPlanShow"
                class="list-group-circle text-left"
              >
                <b-list-group-item
                  v-for="(data,index) in pricing.standardPlan.subscriptionPlanBenefits"
                  :key="index"
                >
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ subscription plan benefit -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data,index) in pricing.standardPlan.planBenefits"
                  :key="index"
                >
                  <del v-if="!data.available">{{ data.message }}</del>
                  <div v-else>
                    {{ data.message }}
                  </div>
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2"
                variant="primary"
                @click="buyProduct('standard')"
              >
                <div v-if="!monthlyPlanShow">
                  Buy Builds
                </div>
                <div v-if="monthlyPlanShow">
                  Subscribe
                </div>
              </b-button>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card
              align="center"
            >
              <!-- img -->
              <b-img
                v-if="pricing.enterprisePlan.img"
                :src="pricing.enterprisePlan.img"
                class="mb-2"
                alt="pricing premium package image"
              />
              <!--/ img -->
              <h3>{{ pricing.enterprisePlan.title }}</h3>
              <b-card-text>{{ pricing.enterprisePlan.subtitle }}</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ !monthlyPlanShow ? pricing.enterprisePlan.buildPrice:pricing.enterprisePlan.monthlyPlan.perMonth }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/{{ monthlyPlanShow ? 'month' : '3 builds' }}</sub>
                </div>
                <small
                  v-show="monthlyPlanShow"
                  class="annual-pricing text-muted"
                >Unlimited builds</small>
              </div>
              <!--/ annual plan -->

              <!-- subscription plan benefit -->
              <b-list-group
                v-if="monthlyPlanShow"
                class="list-group-circle text-left"
              >
                <b-list-group-item
                  v-for="(data,index) in pricing.enterprisePlan.subscriptionPlanBenefits"
                  :key="index"
                >
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ subscription plan benefit -->

              <!-- plan benefit -->
              <b-list-group
                v-for="(data,index) in pricing.enterprisePlan.planBenefits"
                :key="index"
                class="list-group-circle text-left"
              >
                <b-list-group-item>
                  <del v-if="!data.available">{{ data.message }}</del>
                  <div v-else>
                    {{ data.message }}
                  </div>
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                block
                class="mt-2"
                variant="outline-primary"
                @click="buyProduct('premium')"
              >
                <div v-if="!monthlyPlanShow">
                  Buy Builds
                </div>
                <div v-if="monthlyPlanShow">
                  Subscribe
                </div>
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->

    <!-- pricing free trial -->
    <div class="pricing-free-trial">
      <b-row>
        <b-col
          lg="10"
          offset-lg="3"
          class="mx-auto"
        >
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">
                Still not convinced? Start with 3 FREE builds!
              </h3>
              <h5>You will get 3 basic builds to try before buying anything.</h5>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mt-lg-3"
                @click="getFreeBuilds()"
              >
                Get Free Builds
              </b-button>
            </div>

            <!-- images -->
            <b-img
              fluid
              :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
              class="pricing-trial-img"
              alt="pricing free app builds"
            />
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing free trial -->

    <!-- pricing faq -->
    <div class="pricing-faq">
      <h3 class="text-center">
        FAQ's
      </h3>
      <p class="text-center">
        Let us help answer the most common questions.
      </p>
      <b-row class="py-2">
        <b-col
          lg="10"
          offset-lg="2"
          class="mx-auto"
        >
          <app-collapse
            accordion
            type="margin"
          >

            <app-collapse-item
              v-for="(data,index) in pricing.qandA"
              :key="index"
              :title="data.question"
            >
              {{ data.ans }}
            </app-collapse-item>
          </app-collapse>
        </b-col>
      </b-row>
    </div>
    <!--/ pricing faq -->
  </section>
</template>

<script>
import {
  BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: 'build',
      monthlyPlanShow: false,
      pricing: {
        basicPlan: {
          img: 'https://webtoappconvert.web.app/img/Pot1.svg',
          title: 'Basic',
          subtitle: 'A simple start for everyone',
          buildPrice: '4.99',
          monthlyPlan: {
            perMonth: '29.99',
            totalBuild: '100',
          },
          planBenefits: [
            { message: 'App Icon', available: true },
            { message: 'Splash Screen', available: true },
            { message: 'WebToAppConvert.com branding', available: true },
            { message: 'Pull to refresh', available: true },
            { message: 'Onboarding screens', available: false },
            { message: 'Push Notifications', available: false },
            { message: 'AdMob support', available: false },
            { message: 'File download', available: false },
            { message: 'File upload', available: false },
            { message: 'Enable caching', available: false },
            { message: 'Support deep linking', available: false },
            { message: 'Preserve cookies', available: false },
            { message: 'APK/AAB file', available: true },
          ],
          subscriptionPlanBenefits: ['Unlimited Basic builds'],
        },
        standardPlan: {
          popular: true,
          img: 'https://webtoappconvert.web.app/img/Pot2.svg',
          title: 'Standard',
          subtitle: 'Pack of most essential features',
          buildPrice: '9.99',
          monthlyPlan: {
            perMonth: '99.99',
            totalBuild: '100',
          },
          planBenefits: [
            { message: 'App Icon', available: true },
            { message: 'Splash Screen', available: true },
            { message: 'WebToAppConvert.com branding', available: false },
            { message: 'Pull to refresh', available: true },
            { message: 'Onboarding screens', available: true },
            { message: 'Push Notifications', available: true },
            { message: 'AdMob support', available: false },
            { message: 'File download', available: true },
            { message: 'File upload', available: false },
            { message: 'Enable caching', available: true },
            { message: 'Support deep linking', available: false },
            { message: 'Preserve cookies', available: false },
            { message: 'APK/AAB file', available: true },
          ],
          subscriptionPlanBenefits: ['Unlimited Basic builds', 'Unlimited Standard builds'],
        },
        enterprisePlan: {
          popular: true,
          img: 'https://webtoappconvert.web.app/img/Pot3.svg',
          title: 'Premium',
          subtitle: 'With all the features you can imagine',
          buildPrice: '14.99',
          monthlyPlan: {
            perMonth: '149.99',
            totalBuild: '100',
          },
          planBenefits: [
            { message: 'App Icon', available: true },
            { message: 'Splash Screen', available: true },
            { message: 'WebToAppConvert.com branding', available: false },
            { message: 'Pull to refresh', available: true },
            { message: 'Onboarding screens', available: true },
            { message: 'Push Notifications', available: true },
            { message: 'AdMob support', available: true },
            { message: 'File download', available: true },
            { message: 'File upload', available: true },
            { message: 'Enable caching', available: true },
            { message: 'Support deep linking', available: true },
            { message: 'Preserve cookies', available: true },
            { message: 'APK/AAB file', available: true },
          ],
          subscriptionPlanBenefits: ['Unlimited Basic builds', 'Unlimited Standard builds', 'Unlimited Premium builds'],
        },
        qandA: [
          {
            question: 'What are the available payment options?',
            ans:
                'You can pay with your Credit/Debit card or PayPal balance, Also you will be able to Pay with Apple Pay and Google Pay if you select Stripe as the payment gateway in checkout page',
          },
          {
            question: 'My payment was declined,What should I do?',
            ans:
                'Most common cause would be the bank has blocked the transaction, So better to contact the bank. Also you can contact us for any clarification',
          },
          {
            question: 'Does my subscription automatically renew?',
            ans:
                'No. your subscriptions will not renew automatically, you have to extend your subscription by buying it for required number of months',
          },
          {
            question: 'How to cancel my subscription?',
            ans:
                'You does not need to cancel subscription as it won`t renew automatically every month. You have to manually renew the subscription',
          },
          {
            question: 'Does builds get expired?',
            ans:
                'Your builds won`t be expired. They will be kept until you use them. But subscription based builds will be cancelled after the subscription period.',
          },
          {
            question: 'How can I view my transactions?',
            ans:
                'You can view your recent transactions on My-Orders page',
          },
        ],

      },
    }
  },
  created() {
    if (this.$route.query.plan === 'monthly') {
      this.status = 'monthly'
      this.tooglePlan()
    }
  },
  methods: {
    tooglePlan() {
      if (this.status === 'monthly') {
        this.monthlyPlanShow = true
      } else {
        this.monthlyPlanShow = false
      }
    },
    buyProduct(product) {
      let item = product
      const sellingProduct = {
        title: '',
        subtitle: '',
        price: '0.0',
        qty: 1,
      }
      if (this.monthlyPlanShow) { item = `${product}_subscription` }
      if (item === 'basic') {
        sellingProduct.title = this.pricing.basicPlan.title
        sellingProduct.subtitle = this.pricing.basicPlan.subtitle
        sellingProduct.price = this.pricing.basicPlan.buildPrice
      } else if (item === 'basic_subscription') {
        sellingProduct.title = `${this.pricing.basicPlan.title} Subscription`
        sellingProduct.subtitle = this.pricing.basicPlan.subtitle
        sellingProduct.price = this.pricing.basicPlan.monthlyPlan.perMonth
      } else if (item === 'standard') {
        sellingProduct.title = this.pricing.standardPlan.title
        sellingProduct.subtitle = this.pricing.standardPlan.subtitle
        sellingProduct.price = this.pricing.standardPlan.buildPrice
      } else if (item === 'standard_subscription') {
        sellingProduct.title = `${this.pricing.standardPlan.title} Subscription`
        sellingProduct.subtitle = this.pricing.standardPlan.subtitle
        sellingProduct.price = this.pricing.standardPlan.monthlyPlan.perMonth
      } else if (item === 'premium') {
        sellingProduct.title = this.pricing.enterprisePlan.title
        sellingProduct.subtitle = this.pricing.enterprisePlan.subtitle
        sellingProduct.price = this.pricing.enterprisePlan.buildPrice
      } else if (item === 'premium_subscription') {
        sellingProduct.title = `${this.pricing.enterprisePlan.title} Subscription`
        sellingProduct.subtitle = this.pricing.enterprisePlan.subtitle
        sellingProduct.price = this.pricing.enterprisePlan.monthlyPlan.perMonth
      }
      this.$store.dispatch('checkout/setSellingProduct', sellingProduct)
      this.$router.push({ name: 'checkout' })
    },
    getFreeBuilds() {
      this.$router.push({ name: 'register' })
    },
  },
  metaInfo: {
    // Children can override the title.
    title: 'Pricing',
    titleTemplate: '%s | Web To App Convert',
    // Define meta tags here.
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: 'Convert any website to Android Application for free. Pricing details for pay per build or subscriptions.' },
      { name: 'keywords', content: 'Web To App, Convert Web To Android, Android, Website to App, Wordpress to Android, App, Free App Maker, Free, Convert, Website To app, Pricing, Cost, Packages, Subscriptions' },
      // OpenGraph data (Most widely used)
      { property: 'og:title', content: 'Pricing | Web To App Convert' },
      { property: 'og:site_name', content: 'Web To App Convert' },
      // The list of types is available here: http://ogp.me/#types
      { property: 'og:type', content: 'website' },
      // Should the the same as your canonical link, see below.
      { property: 'og:url', content: 'https://www.webtoappconvert.com/pricing' },
      { property: 'og:image', content: 'https://www.webtoappconvert.com/img/logo.png' },
      // Often the same as your meta description, but not always.
      { property: 'og:description', content: 'Convert any website to Android Application for free. Pricing details for pay per build or subscriptions' },
      // Twitter card
      { name: 'twitter:card', content: 'Convert any website to Android Application for free.' },
      { name: 'twitter:site', content: 'https://www.webtoappconvert.com/pricing' },
      { name: 'twitter:title', content: 'Pricing | Web To App Convert' },
      { name: 'twitter:description', content: 'Convert any website to Android Application for free. Pricing details for pay per build or subscriptions' },
      // Your twitter handle, if you have one.
      // { name: 'twitter:creator', content: '@alligatorio' },
      { name: 'twitter:image:src', content: 'https://www.webtoappconvert.com/img/logo.png' },

      // Google / Schema.org markup:
      { itemprop: 'name', content: 'Pricing | Web To App Convert' },
      { itemprop: 'description', content: 'Convert any website to Android Application for free. Pricing details for pay per build or subscriptions' },
      { itemprop: 'image', content: 'https://www.webtoappconvert.com/img/logo.png' },
    ],
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
